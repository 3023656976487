export const lightTheme = {
  body: '#F4F6FF',
  text: '#6E7CAF',
  background: '#ECF4FF',
  inputBackground: '#FFFFFF',
  inputBorder: '#D7E0FF',
  labelColor: "#6E7CAF",
  button: '#567BFF',
  borderColor: '#D7E0FF',
  fieldsColor: '#FFFFFF',
};
export const darkTheme = {
  body: '#05050F',
  text: '#828BAD',
  background: '#05050F',
  inputBackground: '#141722',
  inputBorder: 'transparent',
  labelColor: "#FFFFFF",
  button: '#2D5AF8',
  borderColor: 'transparent',
  fieldsColor: '#141722',
};

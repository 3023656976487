import QRCode from 'qrcode.react';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import Button from '../ui/widgets/Button/Button';
import Field from '../ui/widgets/Field/Field';
import {
  getCardNumberMask,
  getCurrencySymbol,
  getMerchantLogo,
} from '../services/utils';
import { Transaction } from '../services/types';
import card from '../assets/images/card.svg';
import { renderImage } from '../utils/renderImage';
import Header from '../ui/Header';
import InfoBlock from './InfoBlock/InfoBlock';

const Container = styled.div`
  max-width: 500px;
  min-width: 270px;
  max-height: auto;
  background-color: ${({ theme }) => theme.background};
  margin: 0 20px 30px 20px;
  padding: 14px;
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 4px 0px #00000040;
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

const QRCodeDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.inputBackground};
  border-radius: var(--border-radius);
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 10px;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

interface SmallFieldGroupProps {
  direction: 'row' | 'column';
}

const directions = {
  row: css`
    display: flex;
    justify-content: space-between;
    gap: 15px;
  `,
  column: css`
    display: flex;
    flex-direction: column;
    gap: 15px;
  `,
};

const SmallFieldGroup = styled.div<SmallFieldGroupProps>`
  ${(props) => directions[props.direction!]};
  width: ${(props) => (props.direction === 'column' ? '113px' : 'auto')};
`;

SmallFieldGroup.defaultProps = {
  direction: 'row',
};

const Labeled = styled.div`
  display: flex;
  color: ${({ theme }) => theme.labelColor};
  flex-direction: column;
  gap: 7px;
`;

interface P2PFormProps {
  transaction: Transaction;
  timeLeft: string;
  ticketId: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const P2PForm: FC<P2PFormProps> = ({
  transaction,
  ticketId,
  onCancel,
  onConfirm,
  timeLeft,
}) => {
  const { requisite, isRedirectUrlExist, amount } = transaction;

  const currencySymbol = getCurrencySymbol(requisite.currency);

  const requesiteTitle = (type: string | undefined) => {
    switch (type) {
      case 'bank_account':
        return 'Номер счета';
      case 'bank_card':
        return 'Номер карты';
      case 'crypto_wallet':
        return 'Номер кошелька';
      case 'phone_number':
        return 'Номер телефона';
      default:
        return 'Номер реквизита';
    }
  };

  return (
    <>
      <Header
        transaction={transaction}
        ticketId={ticketId}
        timeLeft={timeLeft}
      />
      <Container>
        <ImgWrapper>
          {getMerchantLogo(transaction.merchant) ? (
            <img
              width={85}
              src={getMerchantLogo(transaction.merchant)} //!transaction.merchant
              alt='лого мерчанта'
            />
          ) : (
            <h3>{transaction.merchant.toLocaleUpperCase()}</h3>
          )}
        </ImgWrapper>
        <FieldGroup>
          <Field image={card} text={'По номеру карты'} />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SmallFieldGroup
              direction={requisite.qrCodeData ? 'column' : 'row'}
            >
              {amount && (
                <Labeled>
                  <span>Сумма перевода</span>
                  <Field text={amount} copy currency={currencySymbol} />
                </Labeled>
              )}

              <Labeled>
                <span>Банк</span>
                {renderImage(requisite.acquiringBank) ? (
                  <Field
                    width={amount ? '113px' : '254px'}
                    image={renderImage(requisite.acquiringBank)}
                  />
                ) : (
                  <Field
                    width={amount ? '113px' : '254px'}
                    text={requisite.acquiringBank?.toUpperCase()}
                  />
                )}
              </Labeled>
            </SmallFieldGroup>
            {requisite.qrCodeData && (
              <Labeled>
                <p>QR-код</p>
                <QRCodeDiv>
                  <QRCode size={100} value={requisite.qrCodeData} />
                </QRCodeDiv>
              </Labeled>
            )}
          </div>
          {!isRedirectUrlExist && (
            <Labeled>
              <span>{requesiteTitle(transaction.requisite.type)}</span>
              <Field
                copy
                copyType='clean'
                text={getCardNumberMask(transaction.requisite.cardNumber)}
              />
            </Labeled>
          )}
          {transaction?.requisite?.country &&
            transaction.requisite?.country !== ' ' && (
              <Labeled>
                <span>Страна</span>
                <Field text={transaction?.requisite?.country} />
              </Labeled>
            )}
          <Labeled>
            <span>Получатель</span>
            <Field text={transaction.requisite.name} />
          </Labeled>
          {transaction?.requisite?.userManual && (
            <InfoBlock data={transaction?.requisite?.userManual} />
          )}
          <ButtonGroup>
            <Button variant='secondary' onClick={onCancel}>
              Отмена
            </Button>
            <Button onClick={onConfirm} variant='primary'>
              {isRedirectUrlExist ? 'Перейти к оплате' : 'Оплатил'}
            </Button>
          </ButtonGroup>
        </FieldGroup>
      </Container>
    </>
  );
};

export default P2PForm;

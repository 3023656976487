import QRCode from 'qrcode.react';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import Button from '../ui/widgets/Button/Button';
import Field from '../ui/widgets/Field/Field';
import { getMerchantLogo } from '../services/utils';
import crypto from '../assets/images/crypto.svg';
import btc from '../assets/images/crypto/coins/btc.svg';
import doge from '../assets/images/crypto/coins/doge.svg';
import eth from '../assets/images/crypto/coins/eth.svg';
import ltc from '../assets/images/crypto/coins/ltc.svg';
import not from '../assets/images/crypto/coins/not.svg';
import ton from '../assets/images/crypto/coins/ton.svg';
import usdc from '../assets/images/crypto/coins/usdc.svg';
import usdt from '../assets/images/crypto/coins/usdt.svg';
import erc20 from '../assets/images/erc20.svg';
import trc20 from '../assets/images/trc20.svg';
import { Transaction } from '../services/types';
import Header from '../ui/Header';
import InfoBlock from './InfoBlock/InfoBlock';

const Container = styled.div`
  max-width: 500px;
  min-width: 270px;
  max-height: auto;
  background-color: ${({ theme }) => theme.background};
  margin: 0 20px 30px 20px;
  padding: 14px;
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 4px 0px #00000040;
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

const QRCodeDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.inputBackground};
  border-radius: var(--border-radius);
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 10px;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

interface SmallFieldGroupProps {
  direction: 'row' | 'column';
}

const directions = {
  row: css`
    display: flex;
    justify-content: space-between;
    gap: 15px;
  `,
  column: css`
    display: flex;
    flex-direction: column;
    gap: 15px;
  `,
};

const SmallFieldGroup = styled.div<SmallFieldGroupProps>`
  ${(props) => directions[props.direction!]};
  width: ${(props) => (props.direction === 'column' ? '113px' : 'auto')};
`;

SmallFieldGroup.defaultProps = {
  direction: 'row',
};

const Labeled = styled.div`
  display: flex;
  color: ${({ theme }) => theme.labelColor};
  flex-direction: column;
  gap: 7px;
`;

interface SbpFormProps {
  transaction: Transaction;
  timeLeft: string;
  ticketId: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const CryptoForm: FC<SbpFormProps> = ({
  transaction,
  timeLeft,
  ticketId,
  onConfirm,
  onCancel,
}: SbpFormProps) => {
  const { requisite, isRedirectUrlExist } = transaction;

  const getNetworkData = (networkName: string) => {
    switch (networkName) {
      case 'BTC':
        return { img: btc, text: 'BTC' };
      case 'ETH':
        return { img: eth, text: 'ETH' };
      case 'ERC20':
        return { img: erc20, text: 'ERC20' };
      case 'TRX':
        return { img: trc20, text: 'TRC20' };
      case 'TON':
        return { img: ton, text: 'TON' };
      case 'LTC':
        return { img: ltc, text: 'LTC' };
      case 'DOGE':
        return { img: doge, text: 'DOGE' };
      default:
        break;
    }
  };

  const getCoinImage = (coinName: string) => {
    switch (coinName) {
      case 'BTC(BTC)':
        return btc;
      case 'ETH(ETH)':
        return eth;
      case 'LTC(LTC)':
        return ltc;
      case 'NOT(TON)':
        return not;
      case 'TON(TON)':
        return ton;
      case 'USDT(TON)':
        return usdt;
      case 'USDT(TRX)':
        return usdt;
      case 'USDC(ETH)':
        return usdc;
      case 'DOGE(DOGE)':
        return doge;
      default:
        break;
    }
  };

  return (
    <>
      <Header
        transaction={transaction}
        ticketId={ticketId}
        timeLeft={timeLeft}
      />
      <Container>
        <ImgWrapper>
          {getMerchantLogo(transaction.merchant) ? (
            <img
              width={85}
              src={getMerchantLogo(transaction.merchant)}
              alt='лого мерчанта'
            />
          ) : (
            <h3>{transaction.merchant.toLocaleUpperCase()}</h3>
          )}
        </ImgWrapper>
        <FieldGroup>
          <Field image={crypto} text={'Криптовалюта'} />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SmallFieldGroup
              direction={requisite.qrCodeData ? 'column' : 'row'}
            >
              <Labeled>
                <span>Монета</span>
                <Field
                  image={getCoinImage(transaction.requisite.currency)}
                  text={
                    transaction.requisite.currency?.split('(')[0] ||
                    transaction.requisite.currency
                  }
                />
              </Labeled>

              <Labeled>
                <span>Сеть</span>
                <Field
                  width='113px'
                  image={getNetworkData(transaction.requisite.network)?.img}
                  text={getNetworkData(transaction.requisite.network)?.text}
                />
              </Labeled>
            </SmallFieldGroup>
            {requisite.qrCodeData && (
              <Labeled>
                <p>QR-код</p>
                <QRCodeDiv>
                  <QRCode size={100} value={requisite.qrCodeData} />
                </QRCodeDiv>
              </Labeled>
            )}
          </div>
          {!isRedirectUrlExist && (
            <Labeled>
              <span>Адрес кошелька</span>
              <Field
                width='242px'
                copy
                copyType='full'
                text={transaction.requisite.wallet}
              />
            </Labeled>
          )}
          {transaction?.requisite?.country &&
            transaction.requisite?.country !== ' ' && (
              <Labeled>
                <span>Страна</span>
                <Field text={transaction?.requisite?.country} />
              </Labeled>
            )}
          {transaction?.requisite?.userManual && (
            <InfoBlock data={transaction?.requisite?.userManual} />
          )}
          <ButtonGroup>
            <Button variant='secondary' onClick={onCancel}>
              Отмена
            </Button>
            <Button onClick={onConfirm} variant='primary'>
              {isRedirectUrlExist ? 'Перейти к оплате' : 'Оплатил'}
            </Button>
          </ButtonGroup>
        </FieldGroup>
      </Container>
    </>
  );
};

export default CryptoForm;

import { createGlobalStyle } from 'styled-components';
const GlobalStyles = createGlobalStyle`
  :root {
  }

  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.30s linear;
  }

  li {
    background-color: ${({ theme }) => theme.fieldsColor};
  }
  `;

export default GlobalStyles;
